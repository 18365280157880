document.addEventListener('turbolinks:load', function() {

  //******//
  // MENU //
  //******//

  $('#menuTrigger').on('click', function() {
    $('.menu.responsive').toggleClass('open');
  });

});


$(document).ready(function() {

  //******//
  // LOGO //
  //******//

  const titles = ['studio', 'office', 'practice', 'atelier']
  const subtitles = ['architecture', 'design', 'code', 'build', 'program', 'write', 'think', 'view', 'urbanise'];
  let subIndex, titleIndex = 0;
  const updateSubtitle = () => {
    window.setTimeout(() => {
      subIndex = (subIndex < subtitles.length - 1) ? ++subIndex : 0;
      $('#logoCard .subtitle').text(subtitles[subIndex]);
      updateSubtitle();
    }, 2000);
  }
  updateSubtitle();

  // const updateTitle = () => {
  //   window.setTimeout(() => {
  //     titleIndex = (titleIndex < titles.length - 1) ? ++titleIndex : 0;
  //     $('.logo .title').text(titles[titleIndex]);
  //     updateTitle();
  //   }, 2000);
  // }
  // updateTitle();

});
